<template>
  <div class="content">
    <div class="step">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step title="填写关联信息" />
        <el-step title="填写店铺资料" />
        <el-step title="填写店长信息" />
      </el-steps>
    </div>

    <Relevance v-if="active == 0" :data-msg="data1" @setActive="next" @getData1="getData1" />
    <shopData
      v-if="active == 1"
      :data-msg="data2"
      @setActive="next"
      @setActiveUp="up"
      @getData2="getData2"
    />
    <shopMonitor v-if="active == 2" @setActiveUp="up" @getData3="getData3" />
  </div>
</template>

<script>
import Relevance from "./components/relevance.vue";
import shopData from "./components/shopData.vue";
import shopMonitor from "./components/shopMonitor.vue";
import { createShop } from "@/api/shop";
export default {
  name: "AddShop",
  components: {
    Relevance,
    shopData,
    shopMonitor
  },
  data() {
    return {
      active: 0,
      data1: {},
      data2: {},
      data3: {}
    };
  },

  mounted() {
    this.detail = this.dataMsg;
  },
  methods: {
    // 店铺关联信息
    getData1(e) {
      this.data1 = e;
    },
    // 店铺基本信息
    getData2(e) {
      this.data2 = e;
    },
    // 店长信息
    getData3(e) {
      this.data3 = e;
      this.hint();
    },
    // 下一页
    next() {
      this.active += 1;
    },
    // 上一页
    up() {
      this.active -= 1;
    },
    // 提示
    hint(text) {
      this.$confirm(`确定添加此店铺吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 提交
    confirm() {
      createShop({ ...this.data1, ...this.data2, ...this.data3 }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.$router.push("./shopList");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.step {
  padding: 30px 0;
  ::v-deep .el-step__title.is-success {
    color: #2dc0a3;
    font-weight: bold;
    font-size: 18px;
  }
  ::v-deep .el-step__title.is-process {
    color: #2dc0a3;
    font-weight: bold;
    font-size: 18px;
  }

  ::v-deep .el-step__head.is-success {
    color: #2dc0a3;
    font-weight: bold;
    font-size: 18px;
  }
  ::v-deep .el-step__icon.is-text {
    border-color: #2dc0a3;
    background: #2dc0a3;
    color: #fff;
    font-size: 18px;
    width: 40px;
    height: 40px;
  }
  ::v-deep .el-step__line-inner {
    border-color: #2dc0a3;
  }
  ::v-deep .el-step.is-horizontal .el-step__line {
    top: 20px;
  }
}
</style>
